import axios from 'axios';

export default class Controller {
  // --------------------------------------
  // rest
  // --------------------------------------
  get rest() {
    return axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS, HEAD',
        Authorization: `Bearer ${localStorage.getItem('@TekHook:jwt')}`,
      },
      baseURL: `${window.location.protocol}//${window.location.hostname}:8080`,
    });
  }

  // --------------------------------------
  // dispatchError
  // --------------------------------------
  dispatchError(eventName, error) {
    this.dispatchEvent(new CustomEvent(eventName, { detail: this.tratarMensagemErro(this.getMessageError(error)) }));
  }

  throwError(error) {
    throw this.tratarMensagemErro(this.getMessageError(error));
  }

  // --------------------------------------
  // dispatchResponse
  // --------------------------------------
  dispatchResponse(eventName, response) {
    this.dispatchEvent(new CustomEvent(eventName, { detail: response ? response.data : null }));
  }

  // --------------------------------------
  // getMessageError
  // --------------------------------------
  getMessageError(error) {
    // Quando o servidor está desligado, não possui response
    if (!error.response) {
      return error.message;
    }

    if (!error.response.data.error) {
      return error.response.data;
    }

    return error.response.data.message;
  }

  // --------------------------------------
  // tratarMensagemErro
  // --------------------------------------
  tratarMensagemErro(mensagem) {
    if (mensagem === 'Network Error') {
      return 'Falha de conexão com o servidor!';
    }

    return mensagem;
  }
}
