import Controller from '../Controller';
import jwtDecode from 'jwt-decode';

export default class AuthController extends Controller {
  async login(payload) {
    try {
      const { data } = await this.rest.post('/auth/jwt', payload);
      localStorage.setItem('@TekHook:jwt', data.access_token);
      return data;
    } catch (error) {
      throw this.throwError(error);
    }
  }

  usuarioEstaLogado() {
    const token = localStorage.getItem('@TekHook:jwt');
    if (!token) return false;
    const payload = jwtDecode(token);
    if (!payload) return false;
    const expiraEm = payload.exp;
    const agora = new Date().getTime() / 1000;
    if (agora > expiraEm) return false;

    return true;
  }
}
