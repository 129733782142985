<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
const VApp = () => import('vuetify/es5/components/VApp/VApp');
export default {
  name: 'App',
  components: {
    VApp,
  },
};
</script>
