import Vue from 'vue';
import VueRouter from 'vue-router';
import AuthController from '../services/auth/AuthController';

Vue.use(VueRouter);
const authController = new AuthController();
const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/',
    redirect: '/logs',
    component: () => import('../layouts/DefaultLayout.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '/webhooks',
        name: 'Webhooks',
        component: () => import('../views/Webhooks.vue'),
      },
      {
        path: '/webhook',
        name: 'Novo Webhook',
        component: () => import('../views/Webhook.vue'),
      },
      {
        path: '/webhook/:tokenCliente',
        name: 'Editar Webhook',
        component: () => import('../views/Webhook.vue'),
      },
      {
        path: '/logs',
        name: 'Logs',
        component: () => import('../views/Logs.vue'),
      },
      {
        path: '/log/:id',
        name: 'Log',
        component: () => import('../views/Log.vue'),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(route => route.meta.requiresAuth)) {
    if (!authController.usuarioEstaLogado()) next({ name: 'Login' });
    else next();
  }
  if (to.name === 'Login' && authController.usuarioEstaLogado()) next('/logs');
  else next();
});

export default router;
